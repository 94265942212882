<template>
  <div>
    <div v-if="!$apollo.queries.fines.loading">
      <b-card body-class="p-0" class="animated fadeIn">
        <b-card-header>
          <h4 class="mt-2">
            Histórico de Multas
          </h4>
        </b-card-header>
        <b-card-body class="p-0">
          <b-table responsive striped hover
                   :items="fines.items" :fields="fields"
          >
            <template v-slot:cell(ait)="data">
              <span>{{ data.item.ait }}</span>
            </template>
            
            <template v-slot:cell(issued_at)="data">
              <span>{{ data.item.issued_at | moment('DD/MM/YYYY \@ HH:mm') }}</span>
            </template>
            <template v-slot:cell(images)="data">
              <div @click="openFineImagesModal(data.item)">
                <fine-images :images="data.item.images" />
              </div>
            </template>
            <template v-slot:cell(amount)="data">
              <span style="color:#f86c6b">{{ setFormatPrice(data.item.amount) }}</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="fines.errorType || fines.items.length === 0" class="p-0">
          <content-controll :service="fines" />
        </b-card-body>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>

    <modal-fine-images
      v-model="modalShowFineImages"
      :modal_data="fineOpened"
      @closeModal="closeModal"
    />  
  </div>
</template>

<script>
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import { formatPrice } from '@utils/numbers';
import ModalFineImages from '@components/modals/fines/fine-images';
import FineImages from '@components/fines/images';

import DRIVER_FINES from '@graphql/fine/queries/driver.gql';

export default {
  name: 'DriverFines',
  page: {
    title: 'Motoristas',
  },
  components: {
    ContentControll,
    ContentLoading,
    FineImages,
    ModalFineImages,
  },
  data() {
    return {
      fineOpened: {},
      modalShowFineImages: false,
      fineModal: {},

      fields: [
        {
          key: 'ait',
          label: 'AIT',
          sortable: true,
        },
        {
          key: 'description',
          label: 'Infração',
          sortable: true,
        },
        {
          key: 'car.license_plate',
          label: 'Carro',
        },
        {
          key: 'images',
          label: 'Imagens',
        },
        {
          key: 'issued_at',
          label: 'Data infração',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Valor',
        },
      ],
    };
  },
  apollo: {
    fines: {
      query: DRIVER_FINES,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: data => data.finesByDriver,
    },
  },
  methods: {
    openFineImagesModal( thisItem ) {
      this.modalShowFineImages = true;
      this.fineOpened = thisItem;
    },
    closeModal() {
      this.modalShowFineImages = false;
    },
    setFormatPrice(value) {
      return formatPrice('R$', value);
    },
    goTo(item) {
      const userLink = this.userLink(item.id);
      this.$router.push({
        path: userLink,
      });
    },
    checkIfNew(_issued) {
      const time_now = this.$moment();
      return time_now.diff(this.$moment(_issued), 'days') <= 14;
    },
  },
};
</script>
